import { WP_REST_API_Page, WP_REST_API_Post, WP_REST_API_Posts, WP_REST_API_Tags, WP_REST_API_Users } from 'wp-types'

import { PageType, PostType } from '@/types/wordpress'

const baseUrl = 'https://edhrec.com/articles'

export const formatTag = (tag: string): string => tag.replace(/-/g, ' ').replace('#', '').toLowerCase()

const getAuthorId = async (author: string) => {
  const url = `${baseUrl}/wp-json/wp/v2/users?slug=${author}`
  const res = await fetch(url)
  const authors = (await res.json()) as WP_REST_API_Users
  return authors[0].id
}

export const getPage = async (slug: string): Promise<PageType | undefined> => {
  const url = `${baseUrl}/wp-json/wp/v2/pages?slug=${slug}&_embed`
  const res = await fetch(url)
  const pages = await res.json()
  if (!pages || pages.length === 0) return undefined
  return parsePage(pages[0])
}

export const getPost = async (slug: string, cookies?: any): Promise<PostType | undefined> => {
  const fetchPosts = async (slug: string): Promise<WP_REST_API_Posts> => {
    const url = `${baseUrl}/wp-json/wp/v2/posts?slug=${slug}&_embed`
    if (cookies) {
      const allowedCookies = ['wordpress_logged_in_', 'wp-rest-auth', 'wordpress_sec_']
      const cookieHeader = Object.entries(cookies)
        .filter(([key]) => allowedCookies.some((allowed) => key.startsWith(allowed)))
        .map(([key, value]) => `${key}=${value}`)
        .join('; ')
      const res = await fetch(url, { headers: { Cookie: cookieHeader } })
      if (res.status === 401) {
        throw new Error('Unauthorized')
      }
      const posts = await res.json()
      return posts
    } else {
      const res = await fetch(url)
      const posts = await res.json()
      return posts
    }
  }

  const posts = await fetchPosts(slug)

  if (posts.length === 0) return undefined

  return parsePost(posts[0])
}

export const getPosts = async ({
  author,
  category,
  page = 1,
  search,
  tag,
}: {
  author?: string
  category?: number
  page: number
  search?: string
  tag?: string
}) => {
  const params = new URLSearchParams({
    _embed: '',
    page: `${page}`,
  })

  if (author) {
    const authorId = await getAuthorId(author)
    params.append('author', `${authorId}`)
  }

  if (category) {
    params.append('categories', `${category}`)
  }

  if (search) {
    params.append('search', search)
  }

  if (tag) {
    const tagId = await getTagId(tag)
    params.append('tags', `${tagId}`)
  }

  const url = `${baseUrl}/wp-json/wp/v2/posts?${params.toString()}`

  const res = await fetch(url)
  const pageCount = parseInt(res.headers.get('X-WP-TotalPages') || '1', 10)

  const rawPosts = (await res.json()) as WP_REST_API_Posts
  const posts = rawPosts.map(parsePost)

  return { pageCount, posts }
}

const getTagId = async (tag: string) => {
  const url = `${baseUrl}/wp-json/wp/v2/tags?slug=${tag}`
  const res = await fetch(url)
  const tags = (await res.json()) as WP_REST_API_Tags
  return tags[0].id
}

const parseExcerpt = (content: string): string => {
  const match = content.match(/^<p>(.*?)<\/p>\n$/)
  return match ? match[1] : content
}

export const parsePage = (page: WP_REST_API_Page): PageType => ({
  content: page.content.rendered,
  title: page.title.rendered,
})

export const parsePost = (post: WP_REST_API_Post): PostType => {
  const { _embedded } = post as any
  const rawTags = _embedded['wp:term'].filter((term: any) => term[0]?.taxonomy === 'post_tag')[0]
  return {
    author: {
      avatarUrl: _embedded.author[0].avatar_urls['96'],
      name: _embedded.author[0].name,
      slug: _embedded.author[0].slug,
    },
    content: post.content.rendered,
    databaseId: `${post.id}`,
    date: post.date,
    excerpt: parseExcerpt(post.excerpt.rendered),
    featuredImageUrl: _embedded['wp:featuredmedia'] ? _embedded['wp:featuredmedia'][0].source_url : '',
    slug: post.slug,
    tags: rawTags ? rawTags.map(({ name, slug }: { name: string; slug: string }) => ({ name, slug })) : [],
    title: post.title.rendered,
  }
}
